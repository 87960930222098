// All general stylings

html {
    margin: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body {
    background: #322f37;
    color: #eefcee;
    font-family: "Roboto Slab", serif;
    font-size: 16px;
    overflow-x: hidden !important;
    @media (min-width: 2000px) {
        font-size: 20px;
    }
}

.container-fluid {
    padding: 0 5vw;
    @media (max-width: 480px) {
        padding: 0 10vw;
    }
}

.container-fluid-small {
    padding: 0 15vw;
    @media (max-width: 1600px) {
        padding: 0 10vw;
    }
    @media (min-width: 2000px) {
        padding: 0 20vw;
    }
}

.big-title {
    font-size: 2.5em;
    font-weight: 300;
    letter-spacing: -0.5px;
    color: #eefcee;
    line-height: 1.6;
    @media (max-width: 1400px) {
        font-size: 2em;
    }
    @media (max-width: 768px) {
        font-size: 1.5em;
        padding-right: 0px;
    }
    @media (max-width: 480px) {
        line-height: 2;
    }
}

.sub-title {
    font-weight: 300;
    letter-spacing: 0.5px;
    font-size: 0.9em;
}

p {
    font-size: 1.1em;
    line-height: 1.7;
    color: #eefcee;
    font-weight: 300;
    @media (max-width: 768px) {
        font-size: 0.9em;
    }
    &.fade-p {
        font-size: 0.9em;
        color: #888;
    }
    &.big-p {
        font-size: 1.3em;
        line-height: 1.8;
        @media (max-width: 768px) {
            font-size: 1.1em;
        }
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        font-weight: 300;
        line-height: 2.4;
        letter-spacing: 0.2px;
        font-size: 0.9em;
        color: #eefcee;
    }
}

a {
    transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
    position: relative;
    @media (max-width: 567px) {
        font-size: 0.9em;
    }
    &:after {
        content: "";
        height: 1px;
        width: 100%;
        background: #faf33e;
        position: absolute;
        left: 0;
        right: auto;
        bottom: -3px;
        transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
    }
    &:hover {
        color: #eefcee !important;
        outline: 0;
        text-decoration: none;
        transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        &:after {
            width: 0%;
            right: 0;
            left: auto;
            transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        }
    }
    &:focus {
        outline: 0;
        text-decoration: none;
    }
    &.active {
        color: #eefcee !important;
        outline: 0;
        text-decoration: none;
        transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        &:after {
            width: 100%;
            right: auto;
            left: 0;
            transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        }
    }
}

.alt-link {
    transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
    position: relative;
    @media (max-width: 567px) {
        font-size: 0.9em;
    }
    &:after {
        content: "";
        height: 1px;
        width: 100%;
        background: #faf33e;
        position: absolute;
        left: 0;
        right: auto;
        bottom: -3px;
        transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
    }
    &:hover {
        color: #eefcee !important;
        outline: 0;
        text-decoration: none;
        transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        &:after {
            width: 0%;
            right: 0;
            left: auto;
            transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        }
    }
    &:focus {
        outline: 0;
        text-decoration: none;
    }
    &.active {
        color: #eefcee !important;
        outline: 0;
        text-decoration: none;
        transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        &:after {
            width: 100%;
            right: auto;
            left: 0;
            transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        }
    }
}

.loading-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 9999;
}

@keyframes color-animation {
    0% {
        background: #ff0000;
    }
    25% {
        background: #eefcee;
    }
    50% {
        background: #00ff3b;
    }
    100% {
        background: #0000ff;
    }
}

.colorful-bg {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: color-animation 3s infinite linear alternate;
}

.dark-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #48515b;
}

.scroll-down {
    position: fixed;
    bottom: 4vw;
    right: 5vw;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    @media (max-width: 768px) {
        right: 2vw;
    }
    h5 {
        font-size: 0.9em;
        font-weight: 300;
        margin-right: 15px;
        letter-spacing: 0.5px;
        margin-top: 5px;
        color: #888;
        opacity: 1;
        transition: 0.5s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        @media (max-width: 992px) {
            margin-right: 10px;
        }
        @media (max-width: 768px) {
            margin-right: 5px;
        }
    }
    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        font-size: 16px !important;

        &:after {
            display: none;
        }
    }
    .scroll-arrow {
        height: 39px;
        width: 39px;
        border: 1px solid #222;
        border-radius: 50%;
        transform: rotate(90deg);
        position: relative;
        padding: 0;
        opacity: 1;
        transition: transform 0.8s cubic-bezier(0.75, 0.25, 0.25, 0.75),
            border 0.4s cubic-bezier(0.75, 0.25, 0.25, 0.75),
            opacity 0.5s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        @media (max-width: 992px) {
            transform: rotate(90deg) scale(0.8);
        }
        @media (max-width: 567px) {
            transform: rotate(90deg) scale(0.7);
            border: 1px solid #656565;
        }
        .line {
            position: relative;
            right: -13px;
            top: 1px;
            height: 1px;
            width: 11px;
            background: #656565;
            display: inline-block;
            transition: 0.4s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        }
        .line-1 {
            width: 9px;
            position: relative;
            right: -6px;
            transform: rotate(45deg);
            top: -2px;
        }
        .line-2 {
            width: 9px;
            position: relative;
            right: 3px;
            transform: rotate(-45deg);
            top: 4px;
        }
    }
    &.scroll-top {
        h5 {
            opacity: 0;
            transition: 0.4s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        }
        .scroll-arrow {
            opacity: 0;
            transition: 0.4s cubic-bezier(0.75, 0.25, 0.25, 0.75);
            @media (max-width: 992px) {
                transform: scale(0.8);
            }
            @media (max-width: 567px) {
                transform: scale(0.7);
            }
        }
    }
    &.scroll-top-active {
        .scroll-arrow {
            opacity: 1;
            transform: rotate(-90deg);
            transition: transform 0.8s cubic-bezier(0.75, 0.25, 0.25, 0.75),
                border 0.4s cubic-bezier(0.75, 0.25, 0.25, 0.75),
                opacity 0.5s cubic-bezier(0.75, 0.25, 0.25, 0.75);
            @media (max-width: 992px) {
                transform: rotate(-90deg) scale(0.8);
            }
            @media (max-width: 567px) {
                transform: rotate(-90deg) scale(0.7);
            }
            &:hover {
                border: 1px solid #faf33e;
                transition: transform 0.8s cubic-bezier(0.75, 0.25, 0.25, 0.75),
                    border 0.4s cubic-bezier(0.75, 0.25, 0.25, 0.75);
                .line {
                    background: #faf33e;
                    transition: 0.4s cubic-bezier(0.75, 0.25, 0.25, 0.75);
                }
            }
        }
    }
}

.page-trans {
    position: fixed;
    top: 0;
    left: 0;
    background: #5d737e;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
}
