// All styles for 404 Page

.page-404 {
    h1 {
        input {
            font-size: 0.8em;
        }
        &.big-title {
            margin-bottom: 50px !important;
        }
    }
    a {
        padding: 10px 20px;
        margin: 30px 0 0 0;
        border: 0;
        font-weight: 300;
        font-size: 0.9em;
        color: #eefcee;
        text-align: left;
        transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        position: relative;
        border: 1px solid #faf33e;
        background: transparent;
        display: inline-block;
        width: auto;
        &:after {
            display: none;
        }
        &:hover {
            background: #faf33e;
            transition: 0.25s cubic-bezier(0.75, 0.25, 0.25, 0.75);
        }
        &:focus {
            outline: 0;
            text-decoration: none;
        }
    }
}
.redirect {
    #footerSection {
        background: #050505;
    }
}
