// All styles for Team Component

#aboutTeam {
    padding: 150px 0px;
    @media (max-width: 480px) {
        padding: 100px 0 30px 0px;
    }
    @media (min-width: 2000px) {
        padding: 400px 0px 330px 0px;
    }
    .team-row {
        margin: 0 -35px;
        .team-title {
            padding: 0 35px;
        }
        .team-box {
            padding: 0px 35px;
            margin-bottom: 70px;
            .team-box-title {
                display: flex;
                justify-content: space-between;
                h3 {
                    font-size: 1.3em;
                    font-weight: 300;
                    color: #fff;
                    margin-bottom: 15px;
                    @media (max-width: 768px) {
                        font-size: 1em;
                    }
                }
                a {
                    color: #fff;
                    transition: all 0.1s ease-in-out !important;
                    &:after {
                        display: none;
                    }
                    &:hover {
                        color: #faf33e !important;
                        transition: all 0.1s ease-in-out !important;
                    }
                }
                span {
                    margin-left: 12px;
                    position: relative;
                    bottom: 10px;
                    opacity: 0;
                    transition: all 0.3s ease-in-out !important;
                }
            }
            h5 {
                color: #888;
            }
            img {
                max-width: 100%;
                max-height: 100%;
                margin-bottom: 30px;
            }
            &:hover {
                span {
                    bottom: 2px;
                    opacity: 1;
                    transition: all 0.3s ease-in-out !important;
                }
            }
        }
    }
}
